.origin_olul {
    list-style-type: disc !important;
    padding-left: 40px !important;
    margin-bottom: 16px !important;
}

.col-sm-10 .form-check-label input[type="checkbox"] {
    -webkit-appearance: checkbox
}

.cus_init .cus_image_upload .cus_image_item.main_image:before {
    content: "\e607  ";
    font-family: iconfont;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: .2px;
    -moz-osx-font-smoothing: grayscale;
    white-space: nowrap;
    color: #fff;
    text-shadow: 0 1px 2px #000;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    line-height: 24px;
    height: 24px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    border-radius: 3px
}

.cus_init .cus_image_upload .cus_image_item.set_main_image:before {
    content: "Set as Main";
    font-family: iconfont;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: .2px;
    -moz-osx-font-smoothing: grayscale;
    white-space: nowrap;
    color: #fff;
    text-shadow: 0 1px 2px #000;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    line-height: 24px;
    height: 24px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    border-radius: 3px
}